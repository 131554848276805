export const renderAddress = (address,  state, zip,city) => {
  console.log(address, city, state, zip)
  if (address && city && state && zip) {
    return address + ", " + city + ", " + state + ", " + zip;
  } else if (address && city && zip) {
    return address + ", " + city + ", " + zip;
  } else if (address && state && zip) {
    return address + ", " + state + ", " + zip;
  } else if (address && zip) {
    return address + ", " + zip;
  } else if (address) {
    return address;
  } else {
    return "Not Provided";
  }
};
export const getIsFeatureEnabled = (plan, identifier) => {
  return (
    plan?.features?.filter(
      (feature) => feature.featureId.identifier === identifier
    ).length > 0
  );
};

export const USA_STATES_CAPITALS = [
  {
    id: 1,
    city: "Washington, D.C.",
    zipCodes: [
      "20001",
      "20002",
      "20003",
      "20004",
      "20005",
      "20006",
      "20007",
      "20008",
      "20009",
      "20010",
      "20011",
      "20012",
      "20015",
      "20016",
      "20017",
      "20018",
      "20019",
      "20020",
      "20024",
      "20032",
      "20036",
      "20037",
      "20045",
      "20052",
      "20053",
      "20057",
      "20064",
      "20202",
      "20204",
      "20228",
      "20230",
      "20240",
      "20245",
      "20260",
      "20317",
      "20319",
      "20373",
      "20390",
      "20405",
      "20418",
      "20427",
      "20506",
      "20510",
      "20520",
      "20535",
      "20540",
      "20551",
      "20553",
      "20560",
      "20565",
      "20566",
      "20593",
      "20594",
      "20597",
      "20599",
    ],
  },
  {
    id: 2,
    city: "Montgomery, AL",
    zipCodes: [
      "36101",
      "36102",
      "36103",
      "36104",
      "36105",
      "36106",
      "36107",
      "36108",
      "36109",
      "36110",
      "36111",
      "36112",
      "36113",
      "36114",
      "36115",
      "36116",
      "36117",
      "36118",
      "36119",
      "36120",
      "36121",
      "36123",
      "36124",
      "36125",
      "36130",
      "36131",
      "36132",
      "36133",
      "36134",
      "36135",
      "36140",
      "36141",
      "36142",
      "36177",
      "36191",
    ],
  },
  {
    id: 3,
    city: "Juneau, AK",
    zipCodes: [
      "99801",
      "99802",
      "99803",
      "99811",
      "99812",
      "99820",
      "99821",
      "99824",
      "99825",
      "99826",
      "99827",
      "99829",
      "99830",
      "99832",
      "99833",
      "99835",
      "99836",
      "99840",
      "99841",
      "99850",
    ],
  },
  {
    id: 4,
    city: "Phoenix, AZ",
    zipCodes: [
      "85001",
      "85002",
      "85003",
      "85004",
      "85005",
      "85006",
      "85007",
      "85008",
      "85009",
      "85010",
      "85011",
      "85012",
      "85013",
      "85014",
      "85015",
      "85016",
      "85017",
      "85018",
      "85019",
      "85020",
      "85021",
      "85022",
      "85023",
      "85024",
      "85025",
      "85026",
      "85027",
      "85028",
      "85029",
      "85030",
      "85031",
      "85032",
      "85033",
      "85034",
      "85035",
      "85036",
      "85037",
      "85038",
      "85039",
      "85040",
      "85041",
      "85042",
      "85043",
      "85044",
      "85045",
      "85046",
      "85048",
      "85050",
      "85051",
      "85053",
      "85054",
      "85055",
      "85060",
      "85061",
      "85062",
    ],
  },
  {
    id: 5,
    city: "Little Rock, AR",
    zipCodes: [
      "72201",
      "72202",
      "72203",
      "72204",
      "72205",
      "72206",
      "72207",
      "72209",
      "72210",
      "72211",
      "72212",
      "72214",
      "72215",
      "72216",
      "72217",
      "72219",
      "72221",
      "72222",
      "72223",
      "72225",
      "72227",
      "72231",
      "72260",
      "72295",
      "72296",
      "72297",
      "72298",
    ],
  },
  {
    id: 6,
    city: "Sacramento, CA",
    zipCodes: [
      "94203",
      "94204",
      "94205",
      "94206",
      "94207",
      "94208",
      "94209",
      "94211",
      "94229",
      "94230",
      "94232",
      "94234",
      "94235",
      "94236",
      "94237",
      "94239",
      "94240",
      "94244",
      "94245",
      "94246",
      "94247",
      "94248",
      "94249",
      "94250",
      "94252",
      "94254",
      "94256",
      "94257",
      "94258",
      "94259",
      "94261",
      "94262",
      "94263",
      "94267",
      "94268",
      "94269",
      "94271",
      "94273",
      "94274",
      "94277",
      "94278",
      "94279",
      "94280",
      "94282",
      "94283",
      "94284",
      "94285",
      "94286",
      "94287",
      "94288",
      "94289",
      "94290",
      "94291",
      "94293",
      "94294",
      "94295",
      "94296",
      "94297",
      "94298",
      "94299",
    ],
  },
  {
    id: 7,
    city: "Denver, CO",
    zipCodes: [
      "80002",
      "80012",
      "80014",
      "80022",
      "80033",
      "80123",
      "80127",
      "80201",
      "80202",
      "80203",
      "80204",
      "80205",
      "80206",
      "80207",
      "80208",
      "80209",
      "80210",
      "80211",
      "80212",
      "80214",
      "80215",
      "80216",
      "80217",
      "80218",
      "80219",
      "80220",
      "80221",
      "80222",
      "80223",
      "80224",
      "80225",
      "80226",
      "80227",
      "80228",
      "80229",
      "80230",
      "80231",
      "80232",
      "80233",
      "80234",
      "80235",
      "80236",
      "80237",
      "80238",
      "80239",
      "80241",
      "80243",
      "80244",
      "80246",
      "80247",
      "80248",
      "80249",
      "80250",
      "80251",
      "80252",
      "80256",
      "80257",
      "80259",
      "80260",
      "80261",
      "80262",
      "80263",
      "80264",
      "80265",
      "80266",
      "80271",
      "80273",
      "80274",
      "80281",
      "80290",
      "80291",
      "80293",
      "80294",
      "80295",
      "80299",
    ],
  },
  {
    id: 8,
    city: "Hartford, CT",
    zipCodes: [
      "06101",
      "06102",
      "06103",
      "06104",
      "06105",
      "06106",
      "06112",
      "06114",
      "06115",
      "06116",
      "06118",
      "06119",
      "06120",
      "06123",
      "06126",
      "06127",
      "06128",
      "06129",
      "06131",
      "06132",
      "06133",
      "06134",
      "06137",
      "06138",
      "06140",
      "06141",
      "06142",
      "06143",
      "06144",
      "06145",
      "06146",
      "06147",
      "06150",
      "06151",
      "06152",
      "06153",
      "06154",
      "06155",
      "06156",
      "06160",
      "06161",
      "06167",
      "06176",
      "06180",
      "06183",
      "06199",
    ],
  },
  {
    id: 9,
    city: "Dover, DE",
    zipCodes: [
      "19901",
      "19902",
      "19903",
      "19904",
      "19905",
      "19906",
      "19934",
      "19936",
      "19938",
      "19943",
      "19946",
      "19952",
      "19953",
      "19954",
      "19955",
      "19961",
      "19962",
      "19964",
      "19977",
    ],
  },
  {
    id: 10,
    city: "Tallahassee, FL",
    zipCodes: [
      "32301",
      "32302",
      "32303",
      "32304",
      "32305",
      "32306",
      "32307",
      "32308",
      "32309",
      "32310",
      "32311",
      "32312",
      "32313",
      "32314",
      "32315",
      "32316",
      "32317",
      "32318",
      "32320",
      "32321",
      "32322",
      "32324",
      "32326",
      "32327",
      "32330",
      "32331",
      "32332",
      "32333",
      "32334",
      "32335",
      "32336",
      "32337",
      "32340",
      "32341",
      "32343",
      "32344",
      "32345",
      "32346",
      "32347",
      "32348",
      "32350",
      "32351",
      "32352",
      "32353",
      "32355",
      "32356",
      "32357",
      "32358",
      "32359",
      "32360",
      "32361",
      "32362",
      "32395",
      "32399",
    ],
  },
  {
    id: 11,
    city: "Honolulu, HI",
    zipCodes: [
      "96801",
      "96802",
      "96803",
      "96804",
      "96805",
      "96806",
      "96807",
      "96808",
      "96809",
      "96810",
      "96811",
      "96812",
      "96813",
      "96814",
      "96815",
      "96816",
      "96817",
      "96818",
      "96819",
      "96820",
      "96821",
      "96822",
      "96823",
      "96824",
      "96825",
      "96826",
      "96828",
      "96830",
      "96836",
      "96837",
      "96838",
      "96839",
      "96840",
      "96841",
      "96843",
      "96844",
      "96846",
      "96847",
      "96848",
      "96849",
      "96850",
    ],
  },
  {
    id: 12,
    city: "Boise, ID",
    zipCodes: [
      "83634",
      "83701",
      "83702",
      "83703",
      "83704",
      "83705",
      "83706",
      "83707",
      "83708",
      "83709",
      "83711",
      "83712",
      "83713",
      "83714",
      "83715",
      "83716",
      "83717",
      "83719",
      "83720",
      "83722",
    ],
  },
  {
    id: 13,
    city: "Springfield, MA",
    zipCodes: [
      "01101",
      "01102",
      "01103",
      "01104",
      "01105",
      "01106",
      "01107",
      "01108",
      "01109",
      "01111",
      "01115",
      "01118",
      "01119",
      "01128",
      "01129",
      "01138",
      "01139",
      "01144",
      "01151",
      "01152",
      "01199",
    ],
  },
  {
    id: 14,
    city: "Indianapolis, IN",
    zipCodes: [
      "46201",
      "46202",
      "46203",
      "46204",
      "46205",
      "46206",
      "46207",
      "46208",
      "46209",
      "46211",
      "46214",
      "46216",
      "46217",
      "46218",
      "46219",
      "46220",
      "46221",
      "46222",
      "46223",
      "46224",
      "46225",
      "46226",
      "46227",
      "46228",
      "46229",
      "46230",
      "46231",
      "46234",
      "46235",
      "46236",
      "46237",
      "46239",
      "46240",
      "46241",
      "46242",
      "46244",
      "46247",
      "46249",
      "46250",
      "46251",
      "46253",
      "46254",
      "46255",
      "46256",
      "46259",
      "46260",
      "46262",
      "46266",
      "46268",
      "46274",
      "46275",
      "46277",
      "46278",
      "46280",
      "46282",
      "46283",
      "46285",
      "46290",
      "46291",
      "46295",
      "46296",
      "46298",
    ],
  },
  {
    id: 15,
    city: "Des Moines, IA",
    zipCodes: [
      "50009",
      "50047",
      "50211",
      "50263",
      "50301",
      "50302",
      "50303",
      "50304",
      "50305",
      "50306",
      "50307",
      "50308",
      "50309",
      "50310",
      "50311",
      "50312",
      "50313",
      "50314",
      "50315",
      "50316",
      "50317",
      "50318",
      "50319",
      "50320",
      "50321",
      "50322",
      "50323",
      "50324",
      "50325",
      "50327",
      "50328",
      "50329",
      "50330",
      "50331",
      "50332",
      "50333",
      "50334",
      "50335",
      "50336",
      "50339",
      "50340",
      "50359",
      "50360",
      "50361",
      "50362",
      "50363",
      "50364",
      "50367",
      "50368",
      "50369",
      "50380",
      "50381",
      "50391",
      "50392",
      "50393",
      "50394",
      "50395",
      "50396",
      "50936",
      "50940",
      "50947",
      "50950",
      "50980",
      "50981",
      "50982",
      "50983",
      "50984",
      "50985",
      "50986",
      "50987",
      "50988",
      "50989",
    ],
  },
  {
    id: 16,
    city: "Topeka, KS",
    zipCodes: [
      "66409",
      "66601",
      "66603",
      "66604",
      "66605",
      "66606",
      "66607",
      "66608",
      "66609",
      "66610",
      "66611",
      "66612",
      "66614",
      "66615",
      "66616",
      "66617",
      "66618",
      "66619",
      "66620",
      "66621",
    ],
  },
  {
    id: 17,
    city: "Frankfort , KY",
    zipCodes: [
      "40601",
      "40602",
      "40603",
      "40604",
      "40618",
      "40619",
      "40620",
      "40621",
      "40622",
      "40623",
      "40624",
      "40625",
      "40626",
    ],
  },
  {
    id: 18,
    city: "Baton Rouge, LA",
    zipCodes: [
      "70801",
      "70802",
      "70803",
      "70804",
      "70805",
      "70806",
      "70807",
      "70808",
      "70809",
      "70810",
      "70811",
      "70812",
      "70813",
      "70814",
      "70815",
      "70816",
      "70817",
      "70818",
      "70819",
      "70820",
      "70821",
      "70822",
      "70823",
      "70825",
      "70826",
      "70827",
      "70831",
      "70833",
      "70835",
      "70836",
      "70837",
      "70874",
      "70879",
      "70884",
      "70891",
      "70892",
      "70893",
      "70894",
      "70895",
      "70896",
      "70898",
    ],
  },
  {
    id: 19,
    city: "Augusta, GA",
    zipCodes: [
      "04330",
      "04332",
      "04333",
      "04336",
      "04338",
      "04342",
      "04343",
      "04344",
      "04345",
      "04346",
      "04347",
      "04348",
      "04350",
      "04351",
      "04352",
      "04353",
      "04354",
      "04355",
    ],
  },
  {
    id: 20,
    city: "Annapolis, MD",
    zipCodes: [
      "21401",
      "21402",
      "21403",
      "21404",
      "21405",
      "21409",
      "21411",
      "21412",
    ],
  },
  {
    id: 21,
    city: "Boston, MA",
    zipCodes: [
      "02101",
      "02102",
      "02103",
      "02104",
      "02105",
      "02106",
      "02107",
      "02108",
      "02109",
      "02110",
      "02111",
      "02112",
      "02113",
      "02114",
      "02115",
      "02116",
      "02117",
      "02118",
      "02119",
      "02120",
      "02121",
      "02122",
      "02123",
      "02124",
      "02125",
      "02126",
      "02127",
      "02128",
      "02129",
      "02130",
      "02131",
      "02132",
      "02133",
      "02134",
      "02135",
      "02136",
      "02137",
      "02163",
      "02196",
      "02199",
      "02201",
      "02203",
      "02204",
      "02205",
      "02206",
      "02210",
      "02211",
      "02212",
      "02215",
      "02217",
      "02222",
      "02228",
      "02241",
      "02266",
      "02283",
      "02284",
      "02293",
      "02295",
      "02297",
      "02298",
      "02467",
    ],
  },
  {
    id: 22,
    city: "Lansing, MI",
    zipCodes: [
      "48901",
      "48906",
      "48908",
      "48909",
      "48910",
      "48911",
      "48912",
      "48913",
      "48915",
      "48916",
      "48917",
      "48918",
      "48919",
      "48921",
      "48922",
      "48924",
      "48929",
      "48930",
      "48933",
      "48937",
      "48950",
      "48951",
      "48956",
      "48980",
      "48981",
      "48924",
    ],
  },
  {
    id: 23,
    city: "St. Paul, MN",
    zipCodes: [
      "55101",
      "55102",
      "55103",
      "55104",
      "55105",
      "55106",
      "55107",
      "55108",
      "55109",
      "55110",
      "55111",
      "55112",
      "55113",
      "55114",
      "55115",
      "55116",
      "55117",
      "55118",
      "55119",
      "55120",
      "55121",
      "55122",
      "55123",
      "55124",
      "55125",
      "55126",
      "55127",
      "55128",
      "55129",
      "55130",
      "55133",
      "55144",
      "55145",
      "55146",
      "55155",
      "55164",
      "55165",
      "55166",
      "55168",
      "55169",
      "55170",
      "55171",
      "55172",
      "55175",
      "55187",
      "55188",
    ],
  },
  {
    id: 24,
    city: "Jackson, MS",
    zipCodes: [
      "39201",
      "39202",
      "39203",
      "39204",
      "39205",
      "39206",
      "39207",
      "39208",
      "39209",
      "39210",
      "39211",
      "39212",
      "39213",
      "39215",
      "39216",
      "39217",
      "39218",
      "39225",
      "39232",
      "39236",
      "39250",
      "39269",
      "39271",
      "39282",
      "39283",
      "39284",
      "39286",
      "39288",
      "39289",
      "39296",
      "39298",
    ],
  },
  {
    id: 25,
    city: "Jefferson City, MO",
    zipCodes: [
      "65101",
      "65102",
      "65103",
      "65104",
      "65105",
      "65106",
      "65107",
      "65108",
      "65109",
      "65110",
      "65111",
      "65112",
      "65113",
      "65114",
      "65115",
      "65116",
      "65117",
      "65118",
      "65119",
      "65120",
      "65121",
      "65122",
      "65123",
      "65124",
      "65125",
      "65126",
      "65127",
      "65128",
      "65129",
      "65130",
      "65150",
      "65151",
      "65152",
      "65153",
      "65154",
      "65155",
      "65156",
      "65157",
      "65158",
      "65159",
      "65160",
      "65161",
      "65162",
      "65163",
      "65164",
      "65165",
      "65166",
      "65167",
      "65168",
      "65169",
      "65170",
      "65171",
      "65172",
      "65173",
      "65174",
      "65175",
      "65176",
      "65177",
      "65178",
      "65179",
      "65180",
      "65181",
      "65182",
      "65183",
      "65184",
      "65185",
      "65186",
      "65187",
      "65188",
      "65189",
      "65190",
      "65191",
      "65192",
      "65193",
      "65194",
      "65195",
      "65196",
      "65197",
      "65198",
      "65199",
    ],
  },
  {
    id: 26,
    city: "Lincoln, NE",
    zipCodes: [
      "68501",
      "68502",
      "68503",
      "68504",
      "68505",
      "68506",
      "68507",
      "68508",
      "68509",
      "68510",
      "68512",
      "68514",
      "68516",
      "68517",
      "68520",
      "68521",
      "68522",
      "68523",
      "68524",
      "68526",
      "68527",
      "68528",
      "68529",
      "68531",
      "68532",
      "68542",
      "68544",
      "68583",
      "68588",
    ],
  },
  {
    id: 27,
    city: "Carson City, NV",
    zipCodes: [
      "89701",
      "89702",
      "89703",
      "89704",
      "89705",
      "89706",
      "89711",
      "89712",
      "89713",
      "89714",
      "89721",
      "89723",
      "89750",
    ],
  },
  {
    id: 28,
    city: "Concord, NH",
    zipCodes: ["03301", "03302", "03303", "03305"],
  },
  {
    id: 29,
    city: "Trenton, NJ",
    zipCodes: [
      "08601",
      "08602",
      "08603",
      "08604",
      "08605",
      "08606",
      "08607",
      "08608",
      "08609",
      "08610",
      "08611",
      "08618",
      "08619",
      "08620",
      "08625",
      "08628",
      "08629",
      "08638",
      "08641",
      "08645",
      "08646",
      "08647",
      "08650",
      "08666",
      "08690",
      "08691",
      "08695",
    ],
  },
  {
    id: 30,
    city: "Santa Fe, NM",
    zipCodes: ["87501", "87502", "87503", "87504", "87505", "87506", "87507"],
  },
  {
    id: 31,
    city: "Albany, NY",
    zipCodes: [
      "12201",
      "12202",
      "12203",
      "12204",
      "12205",
      "12206",
      "12207",
      "12208",
      "12209",
      "12210",
      "12211",
      "12212",
      "12214",
      "12220",
      "12222",
      "12223",
      "12224",
      "12225",
      "12226",
      "12227",
      "12228",
      "12229",
      "12230",
      "12231",
      "12232",
      "12233",
      "12234",
      "12235",
      "12236",
      "12237",
      "12238",
      "12239",
      "12240",
      "12241",
      "12242",
      "12243",
      "12244",
      "12245",
      "12246",
      "12247",
      "12248",
      "12249",
      "12250",
      "12252",
      "12255",
      "12256",
      "12257",
      "12260",
      "12261",
      "12288",
    ],
  },
  {
    id: 32,
    city: "Raleigh, NC",
    zipCodes: [
      "27601",
      "27602",
      "27603",
      "27604",
      "27605",
      "27606",
      "27607",
      "27608",
      "27609",
      "27610",
      "27611",
      "27612",
      "27613",
      "27614",
      "27615",
      "27616",
      "27617",
      "27619",
      "27620",
      "27621",
      "27622",
      "27623",
      "27624",
      "27625",
      "27626",
      "27627",
      "27628",
      "27629",
      "27634",
      "27635",
      "27636",
      "27640",
      "27650",
      "27656",
      "27658",
      "27661",
      "27668",
      "27675",
      "27676",
      "27690",
      "27695",
      "27697",
      "27698",
      "27699",
    ],
  },
  {
    id: 33,
    city: "Bismarck, ND",
    zipCodes: [
      "58501",
      "58502",
      "58503",
      "58504",
      "58505",
      "58506",
      "58507",
      "58540",
      "58541",
      "58542",
    ],
  },
  {
    id: 34,
    city: "Columbus, OH",
    zipCodes: [
      "43201",
      "43202",
      "43203",
      "43204",
      "43205",
      "43206",
      "43207",
      "43209",
      "43210",
      "43211",
      "43212",
      "43213",
      "43214",
      "43215",
      "43216",
      "43217",
      "43218",
      "43219",
      "43220",
      "43221",
      "43222",
      "43223",
      "43224",
      "43226",
      "43227",
      "43228",
      "43229",
      "43230",
      "43231",
      "43232",
      "43234",
      "43235",
      "43236",
      "43240",
      "43251",
      "43260",
      "43265",
      "43266",
      "43268",
      "43270",
      "43271",
      "43272",
      "43279",
      "43287",
      "43291",
    ],
  },
  {
    id: 35,
    city: "Oklahoma City, OK",
    zipCodes: [
      "73101",
      "73102",
      "73103",
      "73104",
      "73105",
      "73106",
      "73107",
      "73108",
      "73109",
      "73110",
      "73111",
      "73112",
      "73113",
      "73114",
      "73115",
      "731116",
    ],
  },
  {
    id: 36,
    city: "Salem, OR",
    zipCodes: [
      "97301",
      "97302",
      "97303",
      "97304",
      "97305",
      "97306",
      "97308",
      "97309",
      "97310",
      "97311",
      "97312",
      "97313",
      "97314",
      "97317",
    ],
  },
  {
    id: 37,
    city: "Harrisburg, PA",
    zipCodes: [
      "17101",
      "17102",
      "17103",
      "17104",
      "17105",
      "17106",
      "17107",
      "17108",
      "17109",
      "17110",
      "17111",
      "17112",
      "17113",
      "17120",
      "17121",
      "17122",
      "17123",
      "17124",
      "17125",
      "17126",
      "17127",
      "17128",
      "17129",
      "17130",
      "17140",
      "17177",
    ],
  },
  {
    id: 38,
    city: "Providence, RI",
    zipCodes: [
      "02901",
      "02902",
      "02903",
      "02904",
      "02905",
      "02906",
      "02907",
      "02908",
      "02909",
      "02910",
      "02911",
      "02912",
      "02918",
      "02940",
    ],
  },
  {
    id: 39,
    city: "Columbia, SC",
    zipCodes: [
      "29201",
      "29202",
      "29203",
      "29204",
      "29205",
      "29206",
      "29207",
      "29208",
      "29209",
      "29210",
      "29211",
      "29212",
      "29214",
      "29215",
      "29216",
      "29217",
      "29218",
      "29219",
      "29220",
      "29221",
      "29222",
      "29223",
      "29224",
      "29225",
      "29226",
      "29227",
      "29228",
      "29229",
      "29230",
      "29240",
      "29250",
      "29260",
      "29290",
      "29292",
    ],
  },
  {
    id: 40,
    city: "Pierre, SD",
    zipCodes: ["57501", "57551"],
  },
  {
    id: 41,
    city: "Nashville, TN",
    zipCodes: [
      "37201",
      "37202",
      "37203",
      "37204",
      "37205",
      "37206",
      "37207",
      "37208",
      "37209",
      "37210",
      "37211",
      "37212",
      "37213",
      "37214",
      "37215",
      "37216",
      "37217",
      "37218",
      "37219",
      "37220",
      "37221",
      "37222",
      "37224",
      "37227",
      "37228",
      "37229",
      "37230",
      "37232",
      "37234",
      "37235",
      "37236",
      "37238",
      "37240",
      "37241",
      "37242",
      "37243",
      "37244",
      "37246",
      "37250",
    ],
  },
  {
    id: 42,
    city: "Austin, TX",
    zipCodes: [
      "73301",
      "73344",
      "78701",
      "78702",
      "78703",
      "78704",
      "78705",
      "78708",
      "78709",
      "78710",
      "78711",
      "78712",
      "78713",
      "78714",
      "78715",
      "78716",
      "78717",
      "78718",
      "78719",
      "78720",
      "78721",
      "78722",
      "78723",
      "78724",
      "78725",
      "78726",
    ],
  },
  {
    id: 43,
    city: "Salt Lake City, UT",
    zipCodes: [
      "84101",
      "84102",
      "84103",
      "84104",
      "84105",
      "84106",
      "84107",
      "84108",
      "84109",
      "84110",
      "84111",
      "84112",
      "84113",
      "84114",
      "84115",
      "84116",
      "84117",
      "84118",
      "84119",
      "84120",
      "84121",
      "84122",
      "84123",
      "84124",
      "84125",
      "84126",
      "84127",
      "84128",
      "84130",
      "84131",
      "84132",
      "84133",
      "84134",
      "84136",
      "84138",
      "84139",
      "84141",
      "84143",
      "84145",
      "84147",
      "84148",
      "84150",
      "84151",
      "84152",
      "84157",
      "84158",
      "84165",
      "84170",
      "84171",
      "84180",
      "84184",
      "84189",
      "84190",
      "84199",
    ],
  },
  {
    id: 44,
    city: "Montpelier, VT",
    zipCodes: ["05601"],
  },
  {
    id: 45,
    city: "Richmond, VA",
    zipCodes: [
      "23218",
      "23219",
      "23220",
      "23221",
      "23222",
      "23223",
      "23224",
      "23225",
      "23226",
      "23227",
      "23228",
      "23229",
      "23230",
      "23231",
      "23232",
      "23233",
      "23234",
      "23235",
      "23236",
      "23237",
      "23238",
      "23240",
      "23241",
      "23242",
      "23249",
      "23250",
      "23255",
      "23260",
      "23261",
      "23269",
      "23273",
      "23274",
      "23276",
      "23278",
      "23279",
      "23282",
      "23284",
      "23285",
      "23286",
      "23288",
      "23289",
      "23290",
      "23291",
      "23292",
      "23293",
      "23294",
      "23295",
      "23297",
      "23298",
    ],
  },
  {
    id: 46,
    city: "Olympia, WA",
    zipCodes: [
      "98501",
      "98502",
      "98504",
      "98505",
      "98506",
      "98507",
      "98508",
      "98509",
      "98511",
      "98512",
      "98513",
      "98516",
      "98599",
    ],
  },
  {
    id: 47,
    city: "Charleston, WV",
    zipCodes: [
      "25301",
      "25302",
      "25303",
      "25304",
      "25305",
      "25306",
      "25309",
      "25311",
      "25312",
      "25313",
      "25314",
      "25315",
      "25317",
      "25320",
      "25321",
      "25322",
      "25323",
      "25324",
      "25325",
      "25326",
      "25327",
      "25328",
      "25329",
      "25330",
      "25331",
      "25332",
      "25333",
      "25334",
      "25335",
      "25336",
      "25337",
      "25338",
      "25339",
      "25350",
      "25356",
      "25357",
      "25358",
      "25360",
      "25361",
      "25362",
      "25364",
      "25365",
      "25375",
      "25387",
      "25389",
      "25392",
      "25396",
    ],
  },
  {
    id: 48,
    city: "Madison, WI",
    zipCodes: [
      "53701",
      "53702",
      "53703",
      "53704",
      "53705",
      "53706",
      "53707",
      "53708",
      "53711",
      "53713",
      "53714",
      "53715",
      "53716",
      "53717",
      "53718",
      "53719",
      "53725",
      "53726",
      "53744",
      "53774",
      "53777",
      "53778",
      "53779",
      "53782",
      "53783",
      "53784",
      "53785",
      "53786",
      "53788",
      "53789",
      "53790",
      "53791",
      "53792",
      "53793",
      "53794",
    ],
  },
  {
    id: 49,
    city: "Cheyenne, WY",
    zipCodes: [
      "82001",
      "82002",
      "82003",
      "82004",
      "82005",
      "82006",
      "82007",
      "82008",
      "82009",
      "82010",
      "82051",
    ],
  },
];
